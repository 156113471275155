import { i18n } from './i18n';

export class Translations {
  private _t: Function | undefined;

  constructor() {
    this.editorInit = this.editorInit.bind(this);
  }

  editorInit(locale: any, baseUrl: any) {
    return i18n({ locale, baseUrl }).then(({ t }) => {
      this._t = t;
    });
  }

  t(key: any, options = {}) {
    if (!this._t) {
      throw new Error('Translation service was not initialized yet!');
    }
    return this._t(key, options);
  }
}
